import { useMatches, useParams } from '@remix-run/react'

import { OrganizationMembership } from '@rooquest/common'

export default function useCurrentOrganization() {
  const params = useParams()
  const matches = useMatches()
  let memberships: OrganizationMembership[] = []

  for (const route of matches) {
    if ((route.data as any)?.$memberships) {
      memberships = (route.data as any).$memberships
      break
    }
  }

  const currentOrganization = memberships.find(
    (membership) => membership.organization.slug === params.orgSlug
  )

  if (!currentOrganization) {
    throw new Error('No organization found')
  }

  return currentOrganization.organization
}

export function useCurrentMembershipRole() {
  const params = useParams()
  const matches = useMatches()
  let memberships: OrganizationMembership[] = []

  for (const route of matches) {
    if ((route.data as any)?.$memberships) {
      memberships = (route.data as any).$memberships
      break
    }
  }

  const currentOrganization = memberships.find(
    (membership) => membership.organization.slug === params.orgSlug
  )

  if (!currentOrganization) {
    throw new Error('No organization found')
  }

  return currentOrganization.role
}

export function useMaybeCurrentOrganization() {
  try {
    return useCurrentOrganization()
  } catch (e) {
    return null
  }
}
