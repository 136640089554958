import { Organization, OrganizationBrandingFileType } from '@rooquest/common'

import { useMaybeCurrentOrganization } from '~/hooks/useCurrentOrganization'

export default function useBrandingImage(
  imageType: OrganizationBrandingFileType,
  organization?: Organization
): string {
  let chosenOrganization: Organization | null = null
  const maybeOrganization = useMaybeCurrentOrganization()

  if (organization) {
    chosenOrganization = organization
  } else if (maybeOrganization) {
    chosenOrganization = maybeOrganization
  }

  switch (imageType) {
    case 'senderMenuLogo':
      return chosenOrganization?.senderMenuLogo ?
          chosenOrganization?.senderMenuLogo.file.url
        : '/rooquest-logo-white.png'

    case 'senderMenuIcon':
      return chosenOrganization?.senderMenuIcon ?
          chosenOrganization?.senderMenuIcon.file.url
        : '/gatheroo-icon-white-250.png'

    case 'emailHeaderLogo':
      return chosenOrganization?.emailHeaderLogo ?
          chosenOrganization?.emailHeaderLogo.file.url
        : '/rooquest-logo-official.png'

    case 'recipientHeaderLogo':
      return chosenOrganization?.recipientHeaderLogo ?
          chosenOrganization?.recipientHeaderLogo.file.url
        : '/rooquest-logo-official.png'

    case 'recipientHeaderIcon':
      return chosenOrganization?.recipientHeaderIcon ?
          chosenOrganization?.recipientHeaderIcon.file.url
        : '/gatheroo-icon-official-250.png'

    default:
      return '/rooquest-logo-white.png'
  }
}
