export const buildStyles = {
  card: 'bg-white rounded-[20px] shadow-[0px_8px_24px_0px_rgba(0,_0,_0,_0.08)]',
  activeBorder: 'border border-app-blue-base transition-colors',
  activeInnerShadow:
    'bg-[linear-gradient(to_bottom,_#f7fbff_0%,#ffffff_9%,#ffffff_91%,#f7fbff_100%)]',
}

export const inputStyles = {
  label: 'text-sm text-[#505050] block mb-1',
  input:
    'border border-gray-300 rounded-lg w-full px-2.5 py-1 min-h-[34px] text-gray-600 aria-[invalid]:bg-red-50 aria-[invalid]:border-red-300 data-[invalid=true]:border-red-500 appearance-none',
  focusRing:
    'focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 aria-[invalid]:ring-red-500 data-[invalid=true]:focus:ring-red-500/40',
  focusRingVisible:
    'focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus:-visibleborder-transparent focus-visible:ring-offset-2 aria-[invalid]:ring-red-500',
  range: 'w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700',
  checkbox:
    'h-4 w-4 rounded border-gray-200 text-app-blue-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-app-blue-base disabled:opacity-50 disabled:cursor-not-allowed',
}
