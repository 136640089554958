import { ReactNode, createContext, use, useCallback, useMemo, useState } from 'react'

import ButtonV2 from '~/components/ButtonV2'
import CenterModal from '~/components/CenterModal'
import { RoodyEmotion } from '~/types/roody-emotion'

type ConfirmationHookInput = {
  heading: string
  message: string
  onConfirm: () => void | Promise<void>
  onCancel?: () => void
  confirmLabel?: string
  cancelLabel?: string
  intent?: Parameters<typeof ButtonV2>[0]['intent']
}

type ConfirmationContextType = {
  confirm: (input: ConfirmationHookInput) => void
  handleClose: () => void
}

const ConfirmationContext = createContext<ConfirmationContextType | null>(null)

export function useConfirmation(input: ConfirmationHookInput) {
  const context = use(ConfirmationContext)
  if (!context) {
    throw new Error('useConfirmation must be used within a ConfirmationProvider')
  }
  const confirm = useCallback(() => context.confirm(input), [context, input])
  return [confirm, context.handleClose] as const
}

export function ConfirmationProvider({ children }: { children: ReactNode }) {
  const [modalState, setModalState] = useState<{ isOpen: boolean } & ConfirmationHookInput>({
    isOpen: false,
    heading: '',
    message: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {},
    onCancel: () => {},
  })

  const confirm = useCallback((input: ConfirmationHookInput) => {
    setModalState({
      isOpen: true,
      heading: input.heading,
      message: input.message,
      onConfirm: input.onConfirm,
      onCancel: input.onCancel || (() => {}),
      confirmLabel: input.confirmLabel || 'Yes',
      cancelLabel: input.cancelLabel || 'Cancel',
      intent: input.intent,
    })
  }, [])

  const handleClose = useCallback(
    (fromConfirm = false) => {
      if (!fromConfirm) modalState.onCancel?.()
      return setModalState({ ...modalState, isOpen: false })
    },
    [modalState]
  )

  const handleConfirm = () => {
    modalState.onConfirm()
    handleClose(true)
  }

  const contextValue = useMemo(() => ({ confirm, handleClose }), [confirm, handleClose])

  return (
    <ConfirmationContext value={contextValue}>
      {children}
      <CenterModal
        isOpen={modalState.isOpen}
        onClose={handleClose}
        title={modalState.heading}
        roodyEmotion={RoodyEmotion.WARNING}
        closeElement={
          <ButtonV2 intent="gray-text" onClick={() => handleClose()}>
            {modalState.cancelLabel}
          </ButtonV2>
        }
        doneElement={
          <ButtonV2 onClick={handleConfirm} intent={modalState.intent}>
            {modalState.confirmLabel}
          </ButtonV2>
        }
      >
        <CenterModal.Description className="text-gray-500">
          {modalState.message}
        </CenterModal.Description>
      </CenterModal>
    </ConfirmationContext>
  )
}
