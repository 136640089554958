import { Fragment, JSX, createElement, useEffect, useState } from 'react'

/** React component that renders its children client-side only / after first mount */
export function ClientOnly({
  children,
  fallback,
}: {
  children: React.ReactNode
  fallback?: JSX.Element
}) {
  const hasMounted = useClientOnly()

  if (!hasMounted) {
    return fallback ?? null
  }

  return createElement(Fragment, {}, children)
}

export const useClientOnly = () => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}
