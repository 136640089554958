import cn from 'classnames'
import { JSX, forwardRef } from 'react'

type FieldLabelProps = JSX.IntrinsicElements['label']

function FieldLabel(props: FieldLabelProps, ref: React.Ref<HTMLLabelElement>) {
  const { children, className, ...rest } = props

  return (
    <label ref={ref} className={cn(styles.base, className)} {...rest}>
      {children}
    </label>
  )
}

const styles = {
  // TODO: define design tokens in tailwind config
  base: 'block text-app-gray-dark mb-2 dark:text-white',
}

export default forwardRef(FieldLabel)
