import { type LoaderFunction, redirect } from '@remix-run/node'

import { routes } from '~/config/app-routes'
import { protectLoader } from '~/server/session.server'

export { ErrorBoundary } from '~/components/RouteBoundaries'

export const loader: LoaderFunction = async ({ request, params }) => {
  const { currentUser } = await protectLoader(request)
  return redirect(routes.projects(currentUser.memberships[0].organization.slug))
}

export default function AppIndexRoute() {
  return null
}
