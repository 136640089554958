import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import {
  useAdminContextMenuActions,
  useAdminContextMenuOptions,
  useAdminContextMenuPosition,
  useAdminContextMenuToggleStates,
  useIsAdminContextMenuOpen,
  useSetAdminContextMenuActions,
} from '~/features/admin-context-menu/stores/admin-menu-store'
import useCurrentUser from '~/hooks/useCurrentUser'

type AdminContextMenuProps = {
  triggerRef: React.MutableRefObject<any>
}

export default function AdminContextMenu(props: AdminContextMenuProps) {
  const isOpen = useIsAdminContextMenuOpen()
  const position = useAdminContextMenuPosition()
  const options = useAdminContextMenuOptions()
  const toggleStates = useAdminContextMenuToggleStates()
  const { closeMenu, openMenu, setPosition, toggleOption } = useAdminContextMenuActions()

  const currentUser = useCurrentUser()

  const menuRef = useRef<HTMLDivElement>(null)

  useSetAdminContextMenuActions('Admin Options', [
    { label: 'Admin Mode', toggleable: true },
    {
      label: 'Log currentUser to console',
      onActivate: () => {
        console.log(currentUser)
      },
    },
  ])

  useEffect(() => {
    if (!props.triggerRef.current) return
    if (!currentUser || !currentUser.email.endsWith('@brandcoders.com')) return

    // close on click outside
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef.current?.contains(event.target as Node)) {
        closeMenu()
      }
    }

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault()

      openMenu()
      setPosition({ x: e.clientX, y: e.clientY })
      // flip if too close to the right side
      if (e.clientX > window.innerWidth - 500) {
        setPosition({ x: e.clientX - 200, y: e.clientY })
      }
    }

    props.triggerRef.current.addEventListener('contextmenu', handleContextMenu)
    document.addEventListener('click', handleClickOutside)

    return () => {
      props.triggerRef.current?.removeEventListener('contextmenu', handleContextMenu)
      document.removeEventListener('click', handleClickOutside)
    }
  }, [closeMenu, currentUser, openMenu, props.triggerRef, setPosition])

  if (typeof document === 'undefined' || !isOpen) return null

  return (
    <>
      {createPortal(
        <div
          ref={menuRef}
          className="fixed z-[200] shadow-lg backdrop-blur bg-black/80 text-gray-200 rounded-[12px] p-2 min-w-[240px] max-w-[400px] space-y-2 max-h-[55%] overflow-y-auto"
          style={{ top: position.y, left: position.x }}
        >
          {Object.keys(options).map((heading, headingIndex) => (
            <div key={`admin-context-menu-heading-${headingIndex}`}>
              <h3 className="px-2 pb-0 py-1 text-xs font-medium mb-2 text-gray-400 sticky top-0">
                {heading}
              </h3>

              <ul className={cn(headingIndex !== Object.keys(options).length - 1 && 'pb-2')}>
                {options[heading].map((option, optionIndex) => {
                  const isToggled = option.toggleable && toggleStates[option.label]

                  return (
                    <li key={`admin-context-menu-option-${optionIndex}`}>
                      <button
                        className="flex items-center w-full rounded-lg text-left font-medium text-sm px-2 py-1 hover:bg-blue-700 hover:text-white transition-colors"
                        onClick={() => {
                          if (option.onActivate) {
                            option.onActivate()
                            closeMenu()
                          }
                          if (option.toggleable) toggleOption(option.label)
                        }}
                      >
                        <div className="flex-1">
                          <span>{option.label}</span>
                        </div>

                        <div className="pl-4">
                          {option.toggleable && (
                            <div
                              className={cn(
                                'h-4 w-4 rounded border flex items-center justify-center shadow-sm',
                                isToggled ?
                                  'bg-green-600 border-green-300'
                                : 'bg-transparent border-gray-300'
                              )}
                            >
                              <span>
                                {isToggled ?
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    fixedWidth
                                    className="text-xs text-white"
                                  />
                                : null}
                              </span>
                            </div>
                          )}
                        </div>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </div>,
        document.body
      )}
    </>
  )
}
