import { ToastPosition, toast as hotToast } from 'react-hot-toast'

import Toast from '~/components/Toast'

type ToastOptions = {
  position?: ToastPosition
  duration?: number
  dismissable?: boolean
  showTimer?: boolean
  action?: {
    label: string
    onClick: () => void
  }
}

export const toast = {
  success: (message: string, options?: ToastOptions) =>
    hotToast(
      (hotToast: any) => (
        <Toast
          message={message}
          intent={'success'}
          toastId={hotToast.id}
          duration={hotToast.duration!}
          dismissable={options?.dismissable}
          showTimer={options?.showTimer}
          action={options?.action}
        />
      ),
      {
        position: options?.position ?? 'top-center',
        duration: options?.duration ?? 5000,
        className: 'p-0 mb-1 overflow-hidden',
      }
    ),
  error: (message: string, options?: ToastOptions) =>
    hotToast(
      (hotToast: any) => (
        <Toast
          message={message}
          intent={'error'}
          toastId={hotToast.id}
          duration={hotToast.duration!}
          dismissable={options?.dismissable}
          showTimer={options?.showTimer}
          action={options?.action}
        />
      ),
      {
        position: options?.position ?? 'top-center',
        duration: options?.duration ?? 5000,
        className: 'p-0 mb-1 overflow-hidden',
      }
    ),
  loading: (message: string, options?: ToastOptions) =>
    hotToast(
      (hotToast: any) => (
        <Toast
          message={message}
          intent={'loading'}
          toastId={hotToast.id}
          duration={hotToast.duration!}
          dismissable={options?.dismissable}
          showTimer={options?.showTimer}
        />
      ),
      {
        position: options?.position ?? 'top-center',
        duration: options?.duration ?? 5000,
        className: 'p-0 mb-1 overflow-hidden',
      }
    ),
  warning: (message: string, options?: ToastOptions) =>
    hotToast(
      (hotToast: any) => (
        <Toast
          message={message}
          intent={'warning'}
          toastId={hotToast.id}
          duration={hotToast.duration!}
          dismissable={options?.dismissable}
          showTimer={options?.showTimer}
          action={options?.action}
        />
      ),
      {
        position: options?.position ?? 'top-center',
        duration: options?.duration ?? 5000,
        className: 'p-0 mb-1 overflow-hidden',
      }
    ),
  info: (message: string, options?: ToastOptions) =>
    hotToast(
      (hotToast: any) => (
        <Toast
          message={message}
          intent={'info'}
          toastId={hotToast.id}
          duration={hotToast.duration!}
          dismissable={options?.dismissable}
          showTimer={options?.showTimer}
          action={options?.action}
        />
      ),
      {
        position: options?.position ?? 'top-center',
        duration: options?.duration ?? 5000,
        className: 'p-0 mb-1 overflow-hidden',
      }
    ),
  dismiss: (id: string) => hotToast.dismiss(id),
}
