import cn from 'classnames'
import React, { forwardRef } from 'react'

type AlertProps = {
  color?: keyof typeof styles.color
} & React.JSX.IntrinsicElements['div']

function Alert(props: AlertProps, ref: React.Ref<HTMLDivElement>) {
  const { children, className, color = 'red', ...rest } = props

  return (
    <div
      ref={ref}
      role="alert"
      className={cn(styles.base, styles.color[color], className)}
      {...rest}
    >
      {children}
    </div>
  )
}

const styles = {
  base: 'border rounded-md p-4',
  color: {
    red: 'bg-red-50 border-red-200 text-red-600',
    green: 'bg-green-50 border-green-200 text-green-600',
    blue: 'bg-blue-50 border-blue-200 text-blue-600',
    orange: 'bg-orange-50 border-orange-200 text-orange-600',
    gray: 'bg-gray-50 border-gray-200 text-gray-600',
  },
}

export default forwardRef(Alert)
