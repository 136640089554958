export type GuideConfig = {
  type: 'unobtrusive' | 'obtrusive'
  // position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  enabled: (context: { matchedRoutes: string[] }) => boolean
  steps: {
    key: string
    mediaSrc: string
    heading: string
    content: React.ReactNode
    mediaInteractive?: boolean
  }[]
}

export const guideRegistry = new Map<string, GuideConfig>()

guideRegistry.set('task-builder', {
  type: 'unobtrusive',
  enabled: ({ matchedRoutes }) => {
    return matchedRoutes.includes('routes/_app.$orgSlug_.projects_.$projectId_.$taskId.edit')
  },
  steps: [
    {
      key: 'give-name',
      heading: 'Start with a Name',
      content: 'Give the task a name and instructional message to help your recipient.',
      mediaSrc: '/guides/task-builder/give-name.webm',
    },
    {
      key: 'add-elements',
      heading: 'Add Elements',
      content:
        'Drag elements from the list above into the area in the center to start building the task.',
      mediaSrc: '/guides/task-builder/add-elements.webm',
    },
    {
      key: 'edit-elements',
      heading: 'Edit Element Options',
      content: 'Watch the elements panel change to customize the new element.',
      mediaSrc: '/guides/task-builder/edit-elements.webm',
    },
    {
      key: 'add-more-elements',
      heading: 'Add Additional Elements',
      content:
        'Use the [+] buttons above or below a selected element to add another, or deselect the element and drag from the elements list.',
      mediaSrc: '/guides/task-builder/add-more-elements.webm',
    },
    {
      key: 'preview-task',
      heading: 'Preview the Task',
      content:
        "See the recipient's view using the toggle near the task name. Switch back to build mode to make any changes.",
      mediaSrc: '/guides/task-builder/preview-task.webm',
    },
    {
      key: 'prep-for-send',
      heading: 'Prep for Sending',
      content:
        'When you are done building the task, assign your recipient and set a due date. Now the task is ready to send.',
      mediaSrc: '/guides/task-builder/prep-for-send.webm',
    },
    {
      key: 'send-now-or-later',
      heading: 'Send Now or Later',
      content:
        "You can send this this single task now or exit to build more tasks. Later, send all tasks at once from the project's Task Manager.",
      mediaSrc: '/guides/task-builder/send-now-or-later.webm',
    },
  ],
})

guideRegistry.set('project-task-view', {
  type: 'unobtrusive',
  enabled: ({ matchedRoutes }) => {
    return matchedRoutes.includes('routes/_app.$orgSlug_.projects_.$projectId')
  },
  steps: [
    {
      key: 'navigating-project',
      heading: 'Navigating a Project',
      content:
        'Edit everything for the project in these pages: Task Manager, Activity, Recipients, and Settings.',
      mediaSrc: '/guides/project-task-view/navigating-project.webm',
    },
    {
      key: 'managing-tasks',
      heading: 'Managing Tasks',
      content:
        'Projects are made up of tasks. You can manage all tasks on this page of the project. Add your first task to the project.',
      mediaSrc: '/guides/project-task-view/managing-tasks.webm',
    },
  ],
})
