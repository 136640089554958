import { useNavigate, useRevalidator } from '@remix-run/react'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

import { organizationApiRoutes } from '@rooquest/common'

import Alert from '~/components/Alert'
import ButtonV2 from '~/components/ButtonV2'
import CenterModal from '~/components/CenterModal'
import { routes } from '~/config/app-routes'
import OrganizationAddForm, {
  OrganizationAddFormValues,
} from '~/features/organizations/components/OrganizationAddForm'
import { api } from '~/utils/api-client-singleton.client'
import { toast } from '~/utils/toast'

type ModalAddOrganizationProps = {
  isOpen: boolean
  onClose: () => void
}

export default function ModalAddOrganization(props: ModalAddOrganizationProps) {
  const navigate = useNavigate()
  const revalidator = useRevalidator()

  const [values, setValues] = useState<OrganizationAddFormValues>({
    name: '',
    members: [],
  })

  const handleClose = () => {
    props.onClose()
  }

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async () => {
      const [err, organization] = await api(organizationApiRoutes, 'create', {
        data: { name: values.name, members: values.members },
      })
      if (err) throw new Error('Failed to create organization')

      return organization.data
    },
    onSuccess(organization) {
      revalidator.revalidate()
      props.onClose()
      toast.success('Organization created!', { dismissable: true })
      navigate(routes.projects(organization.slug))
    },
    onError() {
      toast.error('Failed to create organization', { dismissable: true })
    },
  })

  return (
    <CenterModal
      isOpen={props.isOpen}
      onClose={handleClose}
      type="form"
      title="Add Organization"
      doneElement={
        <ButtonV2 disabled={isPending} pending={isPending} onClick={() => mutate()}>
          {isPending ? 'Adding...' : 'Add Organization'}
        </ButtonV2>
      }
      closeElement={
        <ButtonV2 intent="gray-text" onClick={handleClose} disabled={isPending}>
          Cancel
        </ButtonV2>
      }
    >
      {isError && <Alert className="mb-4">Could not create organization</Alert>}

      <CenterModal.Description className="mb-4">
        Make an additional organization for a different business.
      </CenterModal.Description>

      <OrganizationAddForm values={values} setValues={setValues} />
    </CenterModal>
  )
}
