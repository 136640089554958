import { createApi } from '@rooquest/common'

function createNewApiInstance() {
  return createApi(window.env.API_URL, () => {
    try {
      return JSON.parse(
        atob(decodeURIComponent(document.cookie.split('session=')[1].split(';')[0]))
      ).token as string
    } catch {
      return ''
    }
  })
}

const apiProxy = new Proxy(createNewApiInstance(), {
  apply(_, __, args) {
    const apiInstance = createNewApiInstance()
    return apiInstance(...(args as Parameters<typeof apiInstance>))
  },
}) as ReturnType<typeof createNewApiInstance>

export const api = apiProxy
